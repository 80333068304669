import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

import { setAuthToken, getAuthToken } from "../../../internal/authToken";
import { toEnNumber, isNumber } from "../../../utils/Validations";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function CheckOTP(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [auth, setAuth] = React.useState(null);
  const [userNumber, setUserNumber] = React.useState("");
  const [otpCode, setOTPCode] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  let { phone } = useParams();

  useEffect(() => {
    if (phone) {
      setUserNumber(phone);
      setAuth(getAuthToken());
    }
  }, [phone]);

  function handleOTPCodeChange(event) {
    let val = event.target.value;
    let enNumber = toEnNumber(val);

    if (isNumber(enNumber) && val.length === 4) {
      setOTPCode(enNumber);
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handleCheckOTP(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      userNumber !== null &&
      userNumber !== "" &&
      userNumber.length > 10 &&
      userNumber.length < 16 &&
      otpCode !== null &&
      otpCode !== "" &&
      otpCode.length === 4
    ) {
      const rawData = {
        mobile: userNumber,
        otp: otpCode,
        lng: "en",
        client: "webbuilder",
        client_version: "1.0",
      };
      axios({
        method: "post",
        url: config.url.ACCOUNTS_SERVER + "/api/v1/auth/checkotp",
        data: rawData,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            if (res.data.response.secret) {
              setAuthToken({ token: res.data.response.secret });
              setAuth(getAuthToken());

              setTimeout(() => {
                navigate("/dashboard/");
              }, 3000);
            }
          } else {
            if (res.data.response.errors) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Bad request",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid
      item
      xs={12}
      sx={{ backgroundColor: "background.default", minWidth: "100%" }}>
      <Box
        sx={{
          marginTop: "30px",
          minWidth: "40%",
          display: "inline-block",
        }}>
        {auth && auth.token ? (
          <Stack sx={{ textAlign: "start !important" }}>
            <Box>
              <h1>Thanks for registration!</h1>
            </Box>
            <Box sx={{ marginTop: "-25px" }}>
              Now, You are redirection to dashboard...
            </Box>
          </Stack>
        ) : (
          <Stack sx={{ textAlign: "start !important" }}>
            <Stack>
              <Box>
                <h1>SMS Verification</h1>
              </Box>
              <Box sx={{ marginTop: "-25px" }}>
                Please enter SMS code that we sent to you
              </Box>
            </Stack>
            <form autoComplete='off'>
              <TextField
                id='outlined-basic'
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  maxLength: 4,
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}
                sx={{ marginTop: "20px" }}
                type='text'
                required
                margin='normal'
                fullWidth={true}
                placeholder='SMS Code'
                variant='outlined'
                onChange={handleOTPCodeChange}
              />
              <Button
                variant='contained'
                fullwidth='true'
                disableElevation={true}
                disabled={btnDisabled}
                onClick={() => handleCheckOTP()}
                sx={{ minWidth: "100%" }}>
                Check
              </Button>
            </form>
          </Stack>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(CheckOTP);
