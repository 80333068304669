export function isAlpha(val) {
  let ret = false;
  var str = "1234567890۱۲۳۴۵۶۷۸۹۰<>!@#$%^&*()-_=+/.;',،.";
  if (!str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function isAlNum(val) {
  let ret = false;
  var str = "<>!#^&*_=+/;'";
  if (!str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function isNumber(val) {
  let ret = false;
  var str = "1234567890";
  if (str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function toEnNumber(val) {
  var find = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var replace = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var regex;

  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    val = val.replace(regex, replace[i]);
  }
  return val;
}

export function isValidEmail(email) {
  // Regular expression pattern for a basic email validation
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the email against the pattern
  return emailPattern.test(email);
}

export function isValidPassword(password) {
  const minLength = 8;
  const containsNumber = /\d/.test(password);
  const containsUpper = /[A-Z]/.test(password);
  const containsLower = /[a-z]/.test(password);
  const containsSpecial = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);

  return (
    password.length >= minLength &&
    containsNumber &&
    containsUpper &&
    containsLower &&
    containsSpecial
  );
}

// Check if IPv4 address is valid
export function isIPv4Valid(ipv4) {
  const ipv4Pattern =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4Pattern.test(ipv4);
}

// Check if URL is valid
export function isURLValid(url) {
  const urlPattern = /^[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/[\S]*)?$/;
  return urlPattern.test(url);
}

// Check if IPv6 address is valid
export function isIPv6Valid(ipv6) {
  const ipRegex =
    /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

  return ipRegex.test(ipv6);
}

// Check if valid UUID
export function isUUIDValid(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

// Check text against regex
export function isTextMatchRegex(text, regex) {
  return new RegExp(regex).test(text);
}
