import * as React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";

function Home(props) {
  return (
    <Box sx={{ marginTop: "30px", backgroundColor: "background.default" }}>
      <Stack>
        <Box
          sx={{
            borderRadius: "20px",
            padding: "30px",
            border: "1px solid #000000",
          }}>
          <Box>
            <b>MOST ADVANCED VPN</b>
          </Box>
          <Box>
            We offer a diverse range of VPN solutions based on your location.
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "15px",
            borderRadius: "20px",
            padding: "30px",
            border: "1px solid #000000",
          }}>
          <Box>
            <b>NEED TUNNELS?</b>
          </Box>
          <Box>If you want more speed and stability, you can use tunnels.</Box>
        </Box>

        <Box
          sx={{
            marginTop: "15px",
            borderRadius: "20px",
            padding: "30px",
            border: "1px solid #000000",
          }}>
          <Box>
            <b>WE LOVE CRYPTO</b>
          </Box>
          <Box>
            If you want to pay with crypto, send us a message and we will help
            you.
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default Home;
