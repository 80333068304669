const systemReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "SYSTEM_AUTH_TOKEN":
      return { ...state, token: payload };

    case "SYSTEM_SESSION_ROOM":
      return { ...state, room: payload };

    case "SYSTEM_SESSION_USER":
      return { ...state, user: payload };

    case "SYSTEM_USER_UUID":
      return { ...state, uuid: payload };

    case "USER_AUTH":
      return { ...state, getAuth: payload };

    case "SYSTEM_THEME_COLOR":
      return { ...state, themeColor: payload };

    case "SYSTEM_THEME_MODE":
      return { ...state, themeMode: payload };

    case "SYSTEM_THEME_DIR":
      return { ...state, themeDir: payload };

    default:
      return state;
  }
};

export default systemReducer;
