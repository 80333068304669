import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import ChangeServerIcon from "@mui/icons-material/PublishedWithChanges";
import LogsIcon from "@mui/icons-material/Receipt";

import { isIPv4Valid, isUUIDValid } from "../../../../utils/Validations";
import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function ChangeServer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { serveruuid } = useParams();
  const [serverIPv4, setServerIPv4] = React.useState("");
  const [changingServer, setChangingServer] = React.useState(false);
  const [gettingAnsibleLog, setGettingAnsibleLog] = React.useState(false);
  const [showAnsibleLog, setShowAnsibleLog] = React.useState(false);
  const [ansibleLogs, setAnsibleLogs] = React.useState("");
  const logsEndRef = React.useRef(null);

  useEffect(() => {
    logsEndRef.current?.scrollIntoView();
  }, [ansibleLogs]);

  function handleServerIPv4Change(event) {
    setServerIPv4(event.target.value);
  }

  function handleCreateClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (isIPv4Valid(serverIPv4)) {
      setChangingServer(true);
      setShowAnsibleLog(true);
      const rawData = {
        serverUUID: serveruuid,
        serverIPV4: serverIPv4,
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/servers/change",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          setChangingServer(false);
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.message,
                severity: "success",
                autoHide: true,
              })
            );
            //make 2 seconds delay
            setTimeout(() => {
              navigate("/admin/vpn/servers/");
            }, 2000);
          } else {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: res.data.response.error.errorAutoHide,
              })
            );
          }
        })
        .catch((err) => {
          setChangingServer(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: err.response.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  function getAnsibleLog(event) {
    if (event) {
      event.preventDefault();
    }

    if (isUUIDValid(serveruuid)) {
      const rawData = {
        serverUUID: serveruuid,
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setGettingAnsibleLog(true);

      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/servers/getansiblelog",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            setAnsibleLogs(res.data.response.detailedResponse[0].ansibleLog);
            setGettingAnsibleLog(false);
          } else {
            setGettingAnsibleLog(false);
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: res.data.response.error.errorAutoHide,
              })
            );
          }
        })
        .catch((err) => {
          setGettingAnsibleLog(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: err.response.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "70px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          <Stack sx={{ textAlign: "center !important" }}>
            <Box>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontWeight: "bold",
                  fontSize: "24px",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("ADMIN SERVER CHANGE LOCATION")}
              </Typography>
            </Box>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-server-IPv4'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder='Server IP v4'
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerIPv4Change}
            />

            <Button
              variant='contained'
              fullwidth='true'
              disabled={changingServer ? true : false}
              disableElevation={true}
              onClick={() => handleCreateClick()}
              sx={{ minWidth: "100%" }}
              startIcon={<ChangeServerIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {changingServer
                  ? t("ADMIN CHANGE SERVER CHANGING")
                  : t("ADMIN CHANGE SERVER CHANGE")}
              </Box>
            </Button>
            {showAnsibleLog ? (
              <Box sx={{ marginTop: "10px" }}>
                <Paper sx={{ maxHeight: 200, overflow: "auto" }}>
                  {ansibleLogs}
                  <div ref={logsEndRef} />
                </Paper>
                <Button
                  variant='outlined'
                  fullwidth='true'
                  disabled={gettingAnsibleLog ? true : false}
                  disableElevation={true}
                  onClick={() => getAnsibleLog()}
                  sx={{ minWidth: "100%", marginTop: "5px" }}
                  startIcon={<LogsIcon />}>
                  <Box sx={{ marginRight: props.theme.spacing(1) }}>
                    {gettingAnsibleLog
                      ? t("ADMIN ANSIBLE LOG GETTING")
                      : t("ADMIN ANSIBLE LOG GET")}
                  </Box>
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </form>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(ChangeServer);
