import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import { setAuthToken } from "../../../internal/authToken";
import { isValidEmail, isValidPassword } from "../../../utils/Validations";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function Signup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [validEmail, setEmailValid] = React.useState(false);
  const [validPassword, setPasswordValid] = React.useState(false);

  useEffect(() => {}, []);

  function handleEmailChange(event) {
    let email = event.target.value;
    const validEmail = isValidEmail(email);

    setUserEmail(email);
    setEmailValid(validEmail);
    if (validEmail && validPassword) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handlePasswordChange(event) {
    let password = event.target.value;
    const validPassword = isValidPassword(password);

    setUserPassword(password);
    setPasswordValid(validPassword);

    if (validEmail && validPassword) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handleSignupClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      userEmail !== null &&
      userEmail !== "" &&
      userPassword !== null &&
      userPassword !== ""
    ) {
      const rawData = {
        email: userEmail,
        password: userPassword,
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/auth/signin",
        data: rawData,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.message,
                severity: "success",
                autoHide: true,
              })
            );
            setAuthToken({
              token: res.data.response.detailedResponse[0].token,
            });
            //make 2 seconds delay
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          } else {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: res.data.response.error.errorAutoHide,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: err.response.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "70px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          <Stack sx={{ textAlign: "center !important" }}>
            <Box>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("SETTINGS LOGIN BUTTON")}
              </Typography>
            </Box>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-email'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 50,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("SETTINGS LOGIN EMAIL")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleEmailChange}
            />
            <TextField
              id='outlined-password'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 50,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='password'
              required
              margin='normal'
              placeholder={t("SETTINGS LOGIN PASSWORD")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handlePasswordChange}
            />
            <Button
              variant='contained'
              fullwidth='true'
              disabled={btnDisabled}
              disableElevation={true}
              onClick={() => handleSignupClick()}
              sx={{ minWidth: "100%" }}
              startIcon={<LoginIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {t("SETTINGS LOGIN BUTTON")}
              </Box>
            </Button>
          </form>
          <Box sx={{ marginTop: "15px" }}>{t("SETTINGS LOGIN OR")}</Box>
          <Button
            variant='outlined'
            fullwidth='true'
            disableElevation={true}
            onClick={() => navigate("/settings/signup")}
            sx={{ minWidth: "100%" }}
            startIcon={<HowToRegIcon />}>
            <Box sx={{ marginRight: props.theme.spacing(1) }}>
              {t("SETTINGS SIGNUP")}
            </Box>
          </Button>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Signup);
