import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChangeServerIcon from "@mui/icons-material/PublishedWithChanges";

import { getAuthToken } from "../../../../internal/authToken";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function Server(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [auth, setAuth] = React.useState(null);
  const { serveruuid } = useParams();

  function goToServerChange() {
    navigate("/admin/vpn/server/change/" + serveruuid);
  }

  useEffect(() => {
    setAuth(getAuthToken());
  }, [dispatch, navigate]);

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "30px",
          width: "100%",
          display: "inline-block",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        {auth && auth.token ? (
          <Stack spacing={2}>
            <Button
              variant='outlined'
              fullwidth='true'
              onClick={() => goToServerChange()}
              sx={{
                minWidth: "100%",
              }}
              startIcon={<ChangeServerIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {t("ADMIN CHANGE SERVER")}
              </Box>
            </Button>
          </Stack>
        ) : (
          <Box>{t("SETTINGS LOGIN")}</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Server);
