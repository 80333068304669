import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VpnLockIcon from "@mui/icons-material/VpnLock";

import { getAuthToken } from "../../../internal/authToken";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function Admin(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [auth, setAuth] = React.useState(null);

  function goToVPN() {
    navigate("/admin/vpn");
  }

  useEffect(() => {
    setAuth(getAuthToken());
  }, [dispatch, navigate]);

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "30px",
          width: "100%",
          display: "inline-block",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        {auth && auth.token ? (
          <Stack spacing={2}>
            <Button
              variant='outlined'
              fullwidth='true'
              onClick={() => goToVPN()}
              sx={{
                minWidth: "100%",
              }}
              startIcon={<VpnLockIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {t("ADMIN VPN")}
              </Box>
            </Button>
          </Stack>
        ) : (
          <Box>{t("SETTINGS LOGIN")}</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Admin);
