const prod = {
  url: {
    REPLY_SERVER: "https://reply.electera.net",
    APP_SERVER: "https://app.metaguard.net",
    MESSAGING_SERVER: "https://messaging.electera.net",
  },
};
const dev = {
  url: {
    REPLY_SERVER: "http://localhost:4023",
    APP_SERVER: "http://localhost:6051",
    MESSAGING_SERVER: "http://localhost:4021",
  },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
