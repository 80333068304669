import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function VPN(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [auth, setAuth] = React.useState(null);
  const [serversList, setServersList] = React.useState([]);
  const [fetchingList, setFetchingList] = React.useState(true);

  function goToAddNewServer() {
    navigate("/admin/vpn/servers/add");
  }

  useEffect(() => {
    setAuth(getAuthToken());

    function goToServer(serverUUID) {
      if (serverUUID) {
        navigate("/admin/vpn/server/" + serverUUID + "/");
      }
    }

    function getServers() {
      const rawData = { client: "web", clientVersion: "1.0" };
      setFetchingList(true);
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.APP_SERVER + "/api/v1/servers/getservers",
      })
        .then(function (res) {
          setFetchingList(false);
          if (res.data && res.data.response) {
            if (res.data.response.status === 1) {
              let temp = [];
              res.data.response.detailedResponse[0].servers.map(function (
                item,
                key
              ) {
                if (item) {
                  temp.push(
                    <Box key={key}>
                      <ListItemButton
                        onClick={() => goToServer(item.serverUUID)}
                        sx={{ textAlign: "center !important" }}>
                        <ListItemText>
                          {item.serverURL}({item.serverType})
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  );
                }

                return item;
              });
              setServersList(temp);
            } else if (
              res.data.response.status === 0 &&
              res.data.response.errors != null
            ) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setFetchingList(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Error fetching servers",
              severity: "error",
              autoHide: true,
            })
          );
        });
    }

    if (getAuthToken() && getAuthToken().token) {
      getServers();
    }
  }, [dispatch, navigate]);

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "30px",
          width: "100%",
          display: "inline-block",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        {auth && auth.token ? (
          <Stack spacing={2}>
            <Button
              variant='outlined'
              fullwidth='true'
              onClick={() => goToAddNewServer()}
              sx={{
                minWidth: "100%",
              }}
              startIcon={<AddIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {t("ADMIN SERVER CREATE NEW")}
              </Box>
            </Button>
            <br />
            <Typography
              component='div'
              gutterBottom
              sx={{
                textAlign: "center !important",
                color: "primary.title",
                fontWeight: "bold",
                "@media (max-width:600px)": {
                  fontSize: "18px",
                },
              }}>
              {t("ADMIN SERVER SERVERS")}
            </Typography>
            <Box justifyContent='left' alignItems='left'>
              {fetchingList === true ? (
                <Box>
                  <Skeleton />
                  <Skeleton animation={false} />
                  <Skeleton animation='wave' />
                </Box>
              ) : serversList.length > 0 ? (
                serversList
              ) : (
                <Box>{t("ADMIN SERVER NO SERVERS")}</Box>
              )}
            </Box>
          </Stack>
        ) : (
          <Box>{t("SETTINGS LOGIN")}</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(VPN);
