import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  connect,
  // useDispatch
} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForward from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBack from "@mui/icons-material/ArrowBackIosRounded";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";

const MapStateToProps = (state) => {
  return {
    // wsReducer: state.websocketReducer,
    system: state.systemReducer,
  };
};

function CustomAppBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();

  function goBack() {
    console.log();
    if (location.pathname !== "/") {
      navigate(-1);
      // closeEditItemPanel();
    }
  }

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "none",
          boxShadow: "none",
          opacity: "1 !important",
        }}>
        <Toolbar>
          <Typography variant='h6' component='div'>
            <Stack direction='row'>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {location.pathname === "/" ? (
                  <FiberSmartRecordIcon
                    sx={{
                      width: "25px",
                      height: "25px",
                      marginTop: "-2px !important",
                      alt: "metaguard logo",
                      color: "primary.icon",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      margin: 0,
                    }}>
                    {props.system.themeDir === "rtl" ? (
                      <ArrowForward
                        sx={{ color: "primary.icon" }}
                        onClick={() => goBack()}
                      />
                    ) : (
                      <ArrowBack
                        sx={{ color: "primary.icon" }}
                        onClick={() => goBack()}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box></Box>
              <Box sx={{ marginLeft: "5px", marginRight: "5px" }}>
                <Box
                  sx={{
                    color: "primary.title",
                    fontWeight: "bold",
                    fontFamily: "roboto-regular !important",
                  }}>
                  METAGUARD
                </Box>
              </Box>
            </Stack>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default connect(MapStateToProps)(CustomAppBar);
