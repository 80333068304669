import * as React from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import LogsIcon from "@mui/icons-material/Receipt";

import {
  isTextMatchRegex,
  isIPv4Valid,
  isURLValid,
  isUUIDValid,
} from "../../../../utils/Validations";
import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

const serverOptions = {
  xray: [
    {
      name: "trojan",
      tunnels: ["wstunnel"],
      tunnelRequired: true,
    },
  ],
};

function AddServer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [serverName, setServerName] = React.useState("");
  const [serverVersion, setServerVersion] = React.useState("");
  const [vpnVersion, setVpnVersion] = React.useState("");
  const [serverIPv4, setServerIPv4] = React.useState("");
  const [serverURL, setServerURL] = React.useState("");
  const [serverUUID, setServerUUID] = React.useState("");
  const [ansibleLogs, setAnsibleLogs] = React.useState("");
  const [serverPort, setServerPort] = React.useState("");
  const [serverMaxAccount, setServerMaxAccount] = React.useState("");
  const [selectedServerType, setSelectedServerType] = React.useState("");
  const [selectedServerTypeArray, setSelectedServerTypeArray] = React.useState(
    []
  );
  const [selectedVPNTypeValue, setSelectedVPNTypeValue] = React.useState("");
  const [selectedTunnelTypeValue, setSelectedTunnelTypeValue] =
    React.useState("");
  const [selectedTunnelTypeActive, setSelectedTunnelTypeActive] =
    React.useState(false);

  const [creatingServer, setCreatingServer] = React.useState(false);
  const [gettingAnsibleLog, setGettingAnsibleLog] = React.useState(false);
  const [showAnsibleLog, setShowAnsibleLog] = React.useState(false);

  const logsEndRef = React.useRef(null);

  useEffect(() => {
    logsEndRef.current?.scrollIntoView();
  }, [ansibleLogs]);

  const handleServerTypeChange = (event) => {
    const server = event.target.value;
    setSelectedServerType(server);
    setSelectedServerTypeArray(serverOptions[server]);
    setSelectedVPNTypeValue("");
  };

  const handleVPNTypeValueChange = (event) => {
    const selectedVPNType = selectedServerTypeArray.find(
      (c) => c.name === event.target.value
    );

    setSelectedVPNTypeValue(selectedVPNType);
  };

  const handleTunnelTypeChange = (event) => {
    const tunnel = event.target.value;
    setSelectedTunnelTypeValue(tunnel);
    if (tunnel !== "") {
      setSelectedTunnelTypeActive(true);
    }
  };

  function handleServerNameChange(event) {
    setServerName(event.target.value);
  }

  function handleServerVersionChange(event) {
    setServerVersion(event.target.value);
  }

  function handleVpnVersionChange(event) {
    setVpnVersion(event.target.value);
  }

  function handleServerIPv4Change(event) {
    setServerIPv4(event.target.value);
  }

  function handleServerURLChange(event) {
    setServerURL(event.target.value);
  }

  function handleServerPortChange(event) {
    setServerPort(event.target.value);
  }

  function handleServerMaxAccountChange(event) {
    setServerMaxAccount(event.target.value);
  }

  function handleCreateClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      isTextMatchRegex(serverName, "^[a-zA-Z0-9_-]+$") &&
      isTextMatchRegex(selectedServerType, "^[a-zA-Z0-9]+$") &&
      (!selectedVPNTypeValue.tunnelRequired ||
        (selectedVPNTypeValue.tunnelRequired &&
          isTextMatchRegex(selectedTunnelTypeValue, "^[a-zA-Z0-9]+$"))) &&
      isTextMatchRegex(serverVersion, "^[0-9]+(?:.[0-9]+)*$") &&
      isTextMatchRegex(selectedVPNTypeValue.name, "^[a-zA-Z0-9-]+$") &&
      isTextMatchRegex(vpnVersion, "^[0-9]+(?:.[0-9]+)*$") &&
      isIPv4Valid(serverIPv4) &&
      isURLValid(serverURL) &&
      isTextMatchRegex(serverPort, "^[0-9]+$") &&
      isTextMatchRegex(serverMaxAccount, "^[0-9]+$")
    ) {
      let tempUUID = uuidv4();
      setServerUUID(tempUUID);
      const rawData = {
        serverName: serverName,
        serverType: selectedServerType,
        serverUUID: tempUUID,
        serverVersion: serverVersion,
        serverVPNType: selectedVPNTypeValue.name,
        serverVPNVersion: vpnVersion,
        serverIPV4: serverIPv4,
        serverURL: serverURL,
        serverPort: parseInt(serverPort, 10),
        serverMaxAccounts: parseInt(serverMaxAccount, 10),
        serverTunnelActive: selectedTunnelTypeActive,
        serverTunnelType: selectedTunnelTypeValue,
        serverProtocol: "",
        serverExpireDate: "",
        serverIPV6: "",
        serverSSHUsername: "",
        serverSSHPassword: "",
        serverSSHKey: "",
        serverLocation: "",
        serverDescription: "",
        serverProvider: "",
        serverHostingName: "",
        serverHostingUsername: "",
        serverHostingPassword: "",
        serverTargetCountry: "",
        serverTargetOperator: "",
        serverSNIAddress: "",
        serverConfigName: "",
        serverConfigJson: "{}",
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setCreatingServer(true);
      setShowAnsibleLog(true);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/servers/add",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.message,
                severity: "success",
                autoHide: true,
              })
            );
            //make 2 seconds delay
            setTimeout(() => {
              navigate("/admin/vpn/servers/");
            }, 2000);
          } else {
            setCreatingServer(false);
            if (res.data.response) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.error.errorContent[0].message,
                  severity: "error",
                  autoHide: res.data.response.error.errorAutoHide,
                })
              );
            }
          }
        })
        .catch((err) => {
          setCreatingServer(false);
          if (err.response) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content:
                  err.response.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: true,
              })
            );
          }
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  function getAnsibleLog(event) {
    if (event) {
      event.preventDefault();
    }

    if (isUUIDValid(serverUUID)) {
      const rawData = {
        serverUUID: serverUUID,
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setGettingAnsibleLog(true);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/servers/getansiblelog",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            setAnsibleLogs(res.data.response.detailedResponse[0].ansibleLog);
            setGettingAnsibleLog(false);
          } else {
            setGettingAnsibleLog(false);
            if (res.data) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.error.errorContent[0].message,
                  severity: "error",
                  autoHide: res.data.response.error.errorAutoHide,
                })
              );
            }
          }
        })
        .catch((err) => {
          setGettingAnsibleLog(false);
          if (err.response) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content:
                  err.response.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: true,
              })
            );
          }
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "100px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          <Stack sx={{ textAlign: "center !important" }}>
            <Box>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("ADMIN SERVER CREATE NEW")}
              </Typography>
            </Box>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-server-name'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER NAME")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerNameChange}
            />

            <FormControl fullWidth>
              {!selectedServerType && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("ADMIN ADD NEW SERVER TYPE")}
                </InputLabel>
              )}

              <Select
                value={selectedServerType}
                onChange={handleServerTypeChange}
                id='outlined-server-type'
                fullWidth={true}
                shrink='false'
                variant='outlined'
                inputProps={{
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}>
                <MenuItem value='' disabled>
                  <em>{t("ADMIN ADD NEW SERVER TYPE")}</em>
                </MenuItem>
                {Object.keys(serverOptions).map((server) => (
                  <MenuItem key={server} value={server}>
                    {server}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id='outlined-server-version'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "10px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER VERSION")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerVersionChange}
            />
            <FormControl fullWidth disabled={!selectedServerType}>
              {!selectedVPNTypeValue && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("ADMIN ADD NEW SERVER VPN TYPE")}
                </InputLabel>
              )}

              <Select
                value={selectedVPNTypeValue ? selectedVPNTypeValue.name : ""}
                onChange={handleVPNTypeValueChange}>
                <MenuItem value='' disabled>
                  <em>{t("ADMIN ADD NEW SERVER VPN TYPE")}</em>
                </MenuItem>
                {selectedServerTypeArray &&
                  selectedServerTypeArray.map((c) => (
                    <MenuItem key={c.name} value={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: "5px" }}>
              {!selectedTunnelTypeValue && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("DASHBOARD ADD NEW ACCOUNT TUNNEL TYPE")}
                </InputLabel>
              )}
              <Select
                value={selectedTunnelTypeValue}
                onChange={handleTunnelTypeChange}
                disabled={!selectedVPNTypeValue.tunnelRequired}
                id='outlined-tunnel-type'
                fullWidth={true}
                shrink='false'
                variant='outlined'
                inputProps={{
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}>
                <MenuItem value='' disabled>
                  <em>{t("DASHBOARD ADD NEW ACCOUNT TUNNEL TYPE")}</em>
                </MenuItem>
                {selectedVPNTypeValue &&
                  selectedVPNTypeValue.tunnels &&
                  selectedVPNTypeValue.tunnels.map((tunnel) => (
                    <MenuItem key={tunnel} value={tunnel}>
                      {tunnel}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              id='outlined-server-vpn-version'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "10px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER VPN VERSION")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleVpnVersionChange}
            />
            <TextField
              id='outlined-server-ip-v4'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER IPV4")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerIPv4Change}
            />
            <TextField
              id='outlined-server-server-url'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER URL")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerURLChange}
            />
            <TextField
              id='outlined-server-server-port'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER PORT")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerPortChange}
            />
            <TextField
              id='outlined-server-max-account'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW SERVER MAX ACCOUNTS")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleServerMaxAccountChange}
            />
            <Button
              variant='contained'
              fullwidth='true'
              disabled={creatingServer ? true : false}
              disableElevation={true}
              onClick={() => handleCreateClick()}
              sx={{ minWidth: "100%" }}
              startIcon={<AddIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {creatingServer
                  ? t("ADMIN ADD NEW SERVER CREATING")
                  : t("ADMIN ADD NEW SERVER CREATE")}
              </Box>
            </Button>

            {showAnsibleLog ? (
              <Box sx={{ marginTop: "10px" }}>
                <Paper sx={{ maxHeight: 200, overflow: "auto" }}>
                  {ansibleLogs}
                  <div ref={logsEndRef} />
                </Paper>
                <Button
                  variant='outlined'
                  fullwidth='true'
                  disabled={gettingAnsibleLog ? true : false}
                  disableElevation={true}
                  onClick={() => getAnsibleLog()}
                  sx={{ minWidth: "100%", marginTop: "5px" }}
                  startIcon={<LogsIcon />}>
                  <Box sx={{ marginRight: props.theme.spacing(1) }}>
                    {gettingAnsibleLog
                      ? t("ADMIN ANSIBLE LOG GETTING")
                      : t("ADMIN ANSIBLE LOG GET")}
                  </Box>
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </form>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(AddServer);
