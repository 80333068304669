import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import Button from "@mui/material/Button";
import RenewIcon from "@mui/icons-material/AutoMode";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DisableIcon from "@mui/icons-material/WifiOff";
import EnableIcon from "@mui/icons-material/Wifi";
import DeleteIcon from "@mui/icons-material/Delete";

import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function Account(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [auth, setAuth] = React.useState(null);
  const [accountQRString, setAccountQRString] = React.useState("");
  const [account, setAccount] = React.useState({});
  const [fetchingList, setFetchingList] = React.useState(true);
  const { accountuuid } = useParams();
  const [durationDialogOpen, setDurationDialogOpen] = React.useState(false);
  const [durationDialogInput, setDurationDialogInput] = React.useState("");
  const [changinDuration, setChanginDuration] = React.useState(false);
  const [changingStatus, setChangingStatus] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const handleDurationDialogClickOpen = () => {
    setDurationDialogOpen(true);
  };

  const handleDurationDialogClickClose = () => {
    setDurationDialogOpen(false);
  };

  const handleInputChange = (event) => {
    setDurationDialogInput(event.target.value);
  };

  const handleSave = () => {
    handleDurationDialogClickClose();

    if (durationDialogInput > 0 && durationDialogInput < 365) {
      const rawData = {
        accountUUID: accountuuid,
        accountDuration: parseInt(durationDialogInput),
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setChanginDuration(true);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/accounts/extendduration",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            setAccount(res.data.response.detailedResponse[0].account);
            setChanginDuration(false);
          } else {
            setChanginDuration(false);
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: res.data.response.error.errorAutoHide,
              })
            );
          }
        })
        .catch((err) => {
          setChanginDuration(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: err.response.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  };

  const handleAccountStatusChange = () => {
    const rawData = {
      accountUUID: accountuuid,
      accountEnabled: account.accountEnabled === true ? false : true,
      lng: "en",
      client: "web",
      clientVersion: "1.0",
    };
    setChangingStatus(true);
    axios({
      method: "post",
      url: config.url.APP_SERVER + "/api/v1/accounts/setenabled",
      data: rawData,
      headers: {
        Authorization: "Bearer " + getAuthToken().token,
        "Content-Type": "application/json",
      },
      responseType: "json",
    })
      .then(function (res) {
        if (
          res.data &&
          res.data.response.status === 1 &&
          res.data.response.httpCode === 200
        ) {
          account.accountEnabled =
            // res.data.response.detailedResponse[0].account.accountEnabled;
            setAccount(res.data.response.detailedResponse[0].account);
          setChangingStatus(false);
        } else {
          setChangingStatus(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: res.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: res.data.response.error.errorAutoHide,
            })
          );
        }
      })
      .catch((err) => {
        setChangingStatus(false);
        dispatch(
          snackbarActions.newSnackbarAction({
            show: true,
            content: err.response.data.response.error.errorContent[0].message,
            severity: "error",
            autoHide: true,
          })
        );
      });
  };

  const handleAccountDeleteChange = () => {
    const rawData = {
      accountUUID: accountuuid,
      lng: "en",
      client: "web",
      clientVersion: "1.0",
    };
    setDeleting(true);
    axios({
      method: "post",
      url: config.url.APP_SERVER + "/api/v1/accounts/delete",
      data: rawData,
      headers: {
        Authorization: "Bearer " + getAuthToken().token,
        "Content-Type": "application/json",
      },
      responseType: "json",
    })
      .then(function (res) {
        if (
          res.data &&
          res.data.response.status === 1 &&
          res.data.response.httpCode === 200
        ) {
          navigate("/dashboard/vpn/accounts/");
        } else {
          setDeleting(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: res.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: res.data.response.error.errorAutoHide,
            })
          );
        }
      })
      .catch((err) => {
        setDeleting(false);
        dispatch(
          snackbarActions.newSnackbarAction({
            show: true,
            content: err.response.data.response.error.errorContent[0].message,
            severity: "error",
            autoHide: true,
          })
        );
      });
  };

  useEffect(() => {
    setAuth(getAuthToken());

    function getAccount() {
      const rawData = {
        client: "web",
        clientVersion: "1.0",
        accountUUID: accountuuid,
      };
      setFetchingList(true);
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.APP_SERVER + "/api/v1/accounts/getaccount",
      })
        .then(function (res) {
          setFetchingList(false);
          if (res.data && res.data.response) {
            if (res.data.response.status === 1) {
              setAccountQRString(
                res.data.response.detailedResponse[0].account.accountQRString
              );
              setAccount(res.data.response.detailedResponse[0].account);
            } else if (
              res.data.response.status === 0 &&
              res.data.response.errors != null
            ) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setFetchingList(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Error fetching account information",
              severity: "error",
              autoHide: true,
            })
          );
        });
    }

    if (getAuthToken() && getAuthToken().token) {
      getAccount();
    }
  }, [dispatch, navigate, accountuuid]);

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "100px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        {auth && auth.token ? (
          <Stack spacing={2}>
            <Box sx={{ textAlign: "center !important" }}>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("DASHBOARD VPN ACCOUNT INFORMATION")}
              </Typography>
            </Box>
            <Box justifyContent='left' alignItems='left'>
              {fetchingList === true ? (
                <Box>
                  <Skeleton />
                  <Skeleton animation={false} />
                  <Skeleton animation='wave' />
                </Box>
              ) : accountQRString !== "" ? (
                <Stack>
                  <Box>
                    <QRCode value={accountQRString} />
                  </Box>
                  <Box
                    sx={{
                      border: `1px solid ${props.theme.palette.primary.boxBorder}`,
                      borderRadius: 1,
                      padding: "10px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                    }}>
                    {account.accountQRString}
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      border: `1px solid ${props.theme.palette.primary.boxBorder}`,
                      borderRadius: 1,
                      padding: "10px",
                      fontSize: "15px",
                    }}>
                    <Stack
                      direction='row'
                      sx={{
                        width: "100%",
                        fontSize: "15px",
                        textAlign: "start !important",
                        padding: "10px",
                      }}>
                      <Box sx={{ width: "40%", textAlign: "start !important" }}>
                        {t("DASHBOARD VPN ACCOUNT EXPIRE DATE TITLE")}
                      </Box>
                      <Box sx={{ width: "60%", textAlign: "end !important" }}>
                        {account.accountExpireDate}({account.accountDuration}{" "}
                        {t("DASHBOARD VPN ACCOUNT REMAINING DAYS TITLE")})
                      </Box>
                    </Stack>
                    <Box sx={{ marginTop: "10px" }}>
                      <Button
                        variant='outlined'
                        fullWidth
                        disabled={changinDuration}
                        onClick={handleDurationDialogClickOpen}
                        sx={{
                          borderColor: `${props.theme.palette.primary.boxBorder} !important`,
                          color: "#13803b !important",
                          minWidth: "100%",
                        }}
                        startIcon={<RenewIcon />}>
                        <Box sx={{ marginRight: props.theme.spacing(1) }}>
                          {t("DASHBOARD VPN ACCOUNT RENEW BUTTON")}
                        </Box>
                      </Button>

                      <Dialog
                        open={durationDialogOpen}
                        onClose={handleDurationDialogClickClose}>
                        <DialogTitle>
                          {t("ACCOUNTS DIALOG INCREASE REMAINING DAYS")}
                        </DialogTitle>
                        <DialogContent>
                          <Stack>
                            <Box>
                              {t(
                                "ACCOUNTS DIALOG INCREASE REMAINING DAYS DESCRIPTION"
                              )}
                            </Box>
                            <Box>
                              <TextField
                                autoFocus
                                margin='dense'
                                label={t(
                                  "ACCOUNTS DIALOG INCREASE REMAINING TEXTFIELD PLACEHOLDER"
                                )}
                                fullWidth
                                variant='standard'
                                onChange={handleInputChange}
                              />
                            </Box>
                          </Stack>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleDurationDialogClickClose}>
                            {t("BUTTON CANCEL")}
                          </Button>
                          <Button onClick={handleSave}>
                            {t("BUTTON SAVE")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "10px",
                      border: `1px solid ${props.theme.palette.primary.boxBorder}`,
                      borderRadius: 1,
                      padding: "10px",
                      fontSize: "15px",
                    }}>
                    <Stack
                      direction='row'
                      sx={{
                        width: "100%",
                        fontSize: "15px",
                        textAlign: "start !important",
                        padding: "10px",
                      }}>
                      <Box sx={{ width: "40%", textAlign: "start !important" }}>
                        {t("DASHBOARD VPN ACCOUNT STATUS TITLE")}
                      </Box>
                      <Box
                        sx={{
                          width: "60%",
                          textAlign: "end !important",
                          color:
                            account.accountEnabled === true ? "green" : "red",
                        }}>
                        {account.accountEnabled === true
                          ? t("DASHBOARD VPN ACCOUNT STATUS ENABLED")
                          : t("DASHBOARD VPN ACCOUNT STATUS DISABLED") +
                            (account.accountAbuse === true
                              ? "(" +
                                t("DASHBOARD VPN ACCOUNT STATUS ABUSED") +
                                ")"
                              : "")}
                      </Box>
                    </Stack>

                    <Box sx={{ marginTop: "10px" }}>
                      <Button
                        variant='outlined'
                        fullWidth
                        disabled={changingStatus}
                        onClick={handleAccountStatusChange}
                        color={
                          account.accountEnabled === true ? "error" : "success"
                        }
                        sx={{
                          color: `${
                            account.accountEnabled === true
                              ? "error"
                              : "success"
                          } !important`,
                          minWidth: "100%",
                        }}
                        startIcon={
                          account.accountEnabled === true ? (
                            <DisableIcon />
                          ) : (
                            <EnableIcon />
                          )
                        }>
                        <Box sx={{ marginRight: props.theme.spacing(1) }}>
                          {account.accountEnabled === true
                            ? t("DASHBOARD VPN ACCOUNT STATUS SET DISABLED")
                            : t("DASHBOARD VPN ACCOUNT STATUS SET ENABLED")}
                          {changingStatus === true ? "..." : ""}
                        </Box>
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "10px",
                      border: `1px solid ${props.theme.palette.primary.boxBorder}`,

                      borderRadius: 1,
                      padding: "10px",
                      fontSize: "15px",
                    }}>
                    <Box sx={{ marginTop: "10px" }}>
                      <Button
                        variant='outlined'
                        fullWidth
                        disabled={deleting}
                        onClick={handleAccountDeleteChange}
                        color='error'
                        sx={{
                          color: "error",
                          minWidth: "100%",
                        }}
                        startIcon={<DeleteIcon />}>
                        <Box sx={{ marginRight: props.theme.spacing(1) }}>
                          {t("DASHBOARD VPN ACCOUNT DELETE BUTTON")}
                          {deleting === true ? "..." : ""}
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Stack>
              ) : (
                <Box>{t("DASHBOARD VPN ACCOUNT INVALID ACCOUNT")}</Box>
              )}
            </Box>
          </Stack>
        ) : (
          <Box>{t("SETTINGS LOGIN")}</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Account);
