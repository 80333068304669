import * as React from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import LogsIcon from "@mui/icons-material/Receipt";

import {
  isTextMatchRegex,
  isIPv4Valid,
  isURLValid,
  isUUIDValid,
} from "../../../../utils/Validations";
import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

const tunnelOptions = {
  xray: ["wstunnel"],
  // v2ray: ["vless", "vmess"],
};

function AddTunnel(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tunnelName, setTunnelName] = React.useState("");
  const [tunnelVersion, setTunnelVersion] = React.useState("");
  const [tunnelCreatorEmail, setTunnelCreatorEmail] = React.useState("");
  const [tunnelIPv4, setTunnelIPv4] = React.useState("");
  const [tunnelURL, setTunnelURL] = React.useState("");
  const [tunnelUUID, setTunnelUUID] = React.useState("");
  const [ansibleLogs, setAnsibleLogs] = React.useState("");
  const [tunnelPort, setTunnelPort] = React.useState("");
  const [tunnelMaxAccount, setTunnelMaxAccount] = React.useState("");
  const [selectedTunnelVPNType, setSelectedTunnelVPNType] = React.useState("");
  const [selectedTunnelTypeValue, setSelectedTunnelTypeValue] =
    React.useState("");

  const [creatingTunnel, setCreatingTunnel] = React.useState(false);
  const [gettingAnsibleLog, setGettingAnsibleLog] = React.useState(false);
  const [showAnsibleLog, setShowAnsibleLog] = React.useState(false);

  const logsEndRef = React.useRef(null);

  useEffect(() => {
    logsEndRef.current?.scrollIntoView();
  }, [ansibleLogs]);

  const handleTunnelChange = (event) => {
    const tunnel = event.target.value;
    setSelectedTunnelVPNType(tunnel);
    setSelectedTunnelTypeValue("");
  };

  const handleValueChange = (event) => {
    setSelectedTunnelTypeValue(event.target.value);
  };

  function handleTunnelNameChange(event) {
    setTunnelName(event.target.value);
  }

  function handleTunnelVersionChange(event) {
    setTunnelVersion(event.target.value);
  }

  function handleTunnelCreatorEmailChange(event) {
    setTunnelCreatorEmail(event.target.value);
  }

  function handletunnelIPv4Change(event) {
    setTunnelIPv4(event.target.value);
  }

  function handletunnelURLChange(event) {
    setTunnelURL(event.target.value);
  }

  function handletunnelPortChange(event) {
    setTunnelPort(event.target.value);
  }

  function handletunnelMaxAccountChange(event) {
    setTunnelMaxAccount(event.target.value);
  }

  function handleCreateClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      isTextMatchRegex(tunnelName, "^[a-zA-Z0-9_-]+$") &&
      isTextMatchRegex(tunnelVersion, "^[0-9]+(?:.[0-9]+)*$") &&
      isTextMatchRegex(selectedTunnelTypeValue, "^[a-zA-Z0-9_-]+$") &&
      isTextMatchRegex(
        tunnelCreatorEmail,
        "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
      ) &&
      isIPv4Valid(tunnelIPv4) &&
      isURLValid(tunnelURL) &&
      isTextMatchRegex(tunnelPort, "^[0-9]+$") &&
      isTextMatchRegex(tunnelMaxAccount, "^[0-9]+$")
    ) {
      let tempUUID = uuidv4();
      setTunnelUUID(tempUUID);
      const rawData = {
        tunnelName: tunnelName,
        tunnelUUID: tempUUID,
        tunnelVersion: tunnelVersion,
        tunnelType: selectedTunnelTypeValue,
        tunnelCreatorEmail: tunnelCreatorEmail,
        tunnelIPV4: tunnelIPv4,
        tunnelURL: tunnelURL,
        tunnelPort: parseInt(tunnelPort, 10),
        tunnelMaxAccounts: parseInt(tunnelMaxAccount, 10),
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setCreatingTunnel(true);
      setShowAnsibleLog(true);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/tunnels/add",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.message,
                severity: "success",
                autoHide: true,
              })
            );
            //make 2 seconds delay
            setTimeout(() => {
              navigate("/admin/vpn/tunnels/");
            }, 2000);
          } else {
            setCreatingTunnel(false);
            if (res.data) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.error.errorContent[0].message,
                  severity: "error",
                  autoHide: res.data.response.error.errorAutoHide,
                })
              );
            }
          }
        })
        .catch((err) => {
          setCreatingTunnel(false);
          if (err.response) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content:
                  err.response.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: true,
              })
            );
          }
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  function getAnsibleLog(event) {
    if (event) {
      event.preventDefault();
    }

    if (isUUIDValid(tunnelUUID)) {
      const rawData = {
        tunnelUUID: tunnelUUID,
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      setGettingAnsibleLog(true);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/tunnels/getansiblelog",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            setAnsibleLogs(res.data.response.detailedResponse[0].ansibleLog);
            setGettingAnsibleLog(false);
          } else {
            setGettingAnsibleLog(false);
            if (res.data) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.error.errorContent[0].message,
                  severity: "error",
                  autoHide: res.data.response.error.errorAutoHide,
                })
              );
            }
          }
        })
        .catch((err) => {
          setGettingAnsibleLog(false);
          if (err.response) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content:
                  err.response.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: true,
              })
            );
          }
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "100px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          <Stack sx={{ textAlign: "center !important" }}>
            <Box>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("ADMIN TUNNEL CREATE NEW")}
              </Typography>
            </Box>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-tunnel-name'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL NAME")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleTunnelNameChange}
            />

            <FormControl fullWidth>
              {!selectedTunnelVPNType && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("ADMIN ADD NEW TUNNEL VPN TYPE")}
                </InputLabel>
              )}

              <Select
                value={selectedTunnelVPNType}
                onChange={handleTunnelChange}
                id='outlined-tunnel-type'
                fullWidth={true}
                shrink='false'
                variant='outlined'
                inputProps={{
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}>
                <MenuItem value='' disabled>
                  <em>{t("ADMIN ADD NEW TUNNEL VPN TYPE")}</em>
                </MenuItem>
                {Object.keys(tunnelOptions).map((tunnel) => (
                  <MenuItem key={tunnel} value={tunnel}>
                    {tunnel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id='outlined-tunnel-version'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "10px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL VERSION")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleTunnelVersionChange}
            />
            <FormControl fullWidth disabled={!selectedTunnelVPNType}>
              {!selectedTunnelTypeValue && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("ADMIN ADD NEW TUNNEL TYPE")}
                </InputLabel>
              )}

              <Select
                value={selectedTunnelTypeValue}
                onChange={handleValueChange}>
                <MenuItem value='' disabled>
                  <em>{t("ADMIN ADD NEW TUNNEL TYPE")}</em>
                </MenuItem>
                {selectedTunnelVPNType &&
                  tunnelOptions[selectedTunnelVPNType].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              id='outlined-tunnel-creator-email'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "10px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL CREATOR EMAIL")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleTunnelCreatorEmailChange}
            />
            <TextField
              id='outlined-tunnel-ip-v4'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL IPV4")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handletunnelIPv4Change}
            />
            <TextField
              id='outlined-tunnel-tunnel-url'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL URL")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handletunnelURLChange}
            />
            <TextField
              id='outlined-tunnel-tunnel-port'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL PORT")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handletunnelPortChange}
            />
            <TextField
              id='outlined-tunnel-max-account'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("ADMIN ADD NEW TUNNEL MAX ACCOUNTS")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handletunnelMaxAccountChange}
            />
            <Button
              variant='contained'
              fullwidth='true'
              disabled={creatingTunnel ? true : false}
              disableElevation={true}
              onClick={() => handleCreateClick()}
              sx={{ minWidth: "100%" }}
              startIcon={<AddIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {creatingTunnel
                  ? t("ADMIN ADD NEW TUNNEL CREATING")
                  : t("ADMIN ADD NEW TUNNEL CREATE")}
              </Box>
            </Button>

            {showAnsibleLog ? (
              <Box sx={{ marginTop: "10px" }}>
                <Paper sx={{ maxHeight: 200, overflow: "auto" }}>
                  {ansibleLogs}
                  <div ref={logsEndRef} />
                </Paper>
                <Button
                  variant='outlined'
                  fullwidth='true'
                  disabled={gettingAnsibleLog ? true : false}
                  disableElevation={true}
                  onClick={() => getAnsibleLog()}
                  sx={{ minWidth: "100%", marginTop: "5px" }}
                  startIcon={<LogsIcon />}>
                  <Box sx={{ marginRight: props.theme.spacing(1) }}>
                    {gettingAnsibleLog
                      ? t("ADMIN ANSIBLE LOG GETTING")
                      : t("ADMIN ANSIBLE LOG GET")}
                  </Box>
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </form>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(AddTunnel);
