import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import PublicIcon from "@mui/icons-material/Public";
import Box from "@mui/material/Box";

import { saveThemeLng, saveThemeDir } from "../../../internal/saveTheme";
import { setThemeDirAction } from "../../../store/actions/systemAction";

var languages = new Array([]);

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function SimpleDialog(props) {
  const { t } = useTranslation();
  const { onClose, selectedValue, open } = props;
  const dispatch = useDispatch();

  languages = [
    t("SETTINGS LANGUAGE ITEM ENGLISH"),
    t("SETTINGS LANGUAGE ITEM FARSI"),
  ];

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    if (value) {
      onClose(value);
      switch (value) {
        case t("SETTINGS LANGUAGE ITEM ENGLISH"):
          dispatch(setThemeDirAction("ltr"));
          saveThemeDir({ themeDir: "ltr" });
          i18n.changeLanguage("en");
          saveThemeLng({ themeLng: "en" });
          break;

        case t("SETTINGS LANGUAGE ITEM FARSI"):
          dispatch(setThemeDirAction("rtl"));
          saveThemeDir({ themeDir: "rtl" });
          i18n.changeLanguage("fa");
          saveThemeLng({ themeLng: "fa" });
          break;

        default:
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("SETTINGS LANGUAGE SELECT TITLE")}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {languages.map((email) => (
          <ListItem
            button
            onClick={() => handleListItemClick(email)}
            key={email}>
            <ListItemText primary={email} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

function LanguageSelect(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(languages[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button
        variant='outlined'
        fullwidth='true'
        sx={{
          marginTop: "10px",
          minWidth: "100%",
        }}
        onClick={handleClickOpen}
        startIcon={<PublicIcon />}>
        <Box sx={{ marginRight: props.theme.spacing(1) }}>
          {(() => {
            switch (i18n.language) {
              case "en":
                return t("SETTINGS LANGUAGE ITEM ENGLISH");
              case "fa":
                return t("SETTINGS LANGUAGE ITEM FARSI");
              default:
                return "";
            }
          })()}
        </Box>
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

export default connect(MapStateToProps)(LanguageSelect);
