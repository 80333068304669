export function saveThemeMode(theme) {
  if (theme) {
    localStorage.setItem("metaguard_theme_mode", JSON.stringify(theme));
  }
}

export function getThemeMode() {
  return JSON.parse(localStorage.getItem("metaguard_theme_mode"));
}

export function saveThemeColor(theme) {
  if (theme) {
    localStorage.setItem("metaguard_theme_color", JSON.stringify(theme));
  }
}

export function getThemeColor() {
  return JSON.parse(localStorage.getItem("metaguard_theme_color"));
}

export function saveThemeDir(theme) {
  if (theme) {
    localStorage.setItem("metaguard_theme_dir", JSON.stringify(theme));
  }
}

export function getThemeDir() {
  return JSON.parse(localStorage.getItem("metaguard_theme_dir"));
}

export function saveThemeLng(theme) {
  if (theme) {
    localStorage.setItem("metaguard_theme_lng", JSON.stringify(theme));
  }
}

export function getThemeLng() {
  return JSON.parse(localStorage.getItem("metaguard_theme_lng"));
}
