import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import i18n from "i18next";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";

// import { loadProgressBar } from "axios-progress-bar";

import setTheme from "../../styles/theme/mainTheme";
import PrivateRoute from "../../utils/PrivateRoute";
import Dashboard from "../Dashboard/Dashboard/Dashboard";
import Admin from "../Admin/Admin/Admin";
import VPN from "../Admin/vpn/VPN";
import AddServer from "../Admin/Server/AddServer/AddServer";
import AddTunnel from "../Admin/Tunnel/AddTunnel/AddTunnel";
import Server from "../Admin/Server/Server/Server";
import ChangeServer from "../Admin/Server/ChangeServer/ChangeServer";
import AddAccount from "../Dashboard/AddAccount/AddAccount";
import Accounts from "../Dashboard/Accounts/Accounts";
import Account from "../Dashboard/Account/Account";
import Home from "../Home/Home";
import CustomAppBar from "../UI/CustomAppBar/CustomAppBar";
import CustomBottomNavigation from "../UI/CustomBottomNavigation/CustomBottomNavigation";
import SnackBarMessage from "../UI/Snackbar";
import Settings from "../Settings/Settings/Settings";
import Login from "../Settings/Login/Login";
import Signup from "../Settings/Signup/Signup";
import CheckOTP from "../Settings/CheckOTP/CheckOTP";
import {
  setThemeModeAction,
  setThemeDirAction,
  setThemeColorAction,
} from "../../store/actions/systemAction";
import {
  getThemeMode,
  getThemeColor,
  getThemeDir,
  getThemeLng,
} from "../../internal/saveTheme";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function App(props) {
  const dispatch = useDispatch();
  // loadProgressBar({ showSpinner: false });

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  useEffect(() => {
    if (getThemeLng()) {
      i18n.changeLanguage(getThemeLng().themeLng);
    }

    if (getThemeColor()) {
      dispatch(setThemeColorAction(getThemeColor().themeColor));
    }

    if (getThemeDir()) {
      dispatch(setThemeDirAction(getThemeDir().themeDir));
    }

    if (getThemeMode()) {
      dispatch(setThemeModeAction(getThemeMode().themeMode));
    }
  }, [dispatch]);

  //we use this for dynamically change the color wth changing state
  const dynamicTheme = React.useMemo(
    () =>
      createTheme(
        setTheme(
          props.system.themeColor,
          props.system.themeMode,
          props.system.themeDir
        )
      ),
    [props.system.themeColor, props.system.themeMode, props.system.themeDir]
  );

  return (
    <React.Fragment>
      <Grid container alignItems='center' justifyContent='center' spacing={2}>
        <Grid
          item
          xs={11}
          sm={9}
          md={6}
          sx={{
            width: "100%",
            textAlign: "center",
          }}>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={dynamicTheme}>
              <CssBaseline />
              <Box
                alignItems='center'
                justifyContent='center'
                dir={props.system.themeDir}>
                <BrowserRouter>
                  <SnackBarMessage />
                  <CustomAppBar />
                  <Routes>
                    <Route
                      exact
                      path='/'
                      element={<Home theme={dynamicTheme} />}
                    />
                    <Route
                      exact
                      path='/settings/'
                      element={<Settings theme={dynamicTheme} />}
                    />
                    <Route
                      exact
                      path='/settings/login/'
                      element={<Login theme={dynamicTheme} />}
                    />
                    <Route
                      exact
                      path='/settings/signup/'
                      element={<Signup theme={dynamicTheme} />}
                    />
                    <Route
                      exact
                      path='/settings/login/check/:phone'
                      element={<CheckOTP theme={dynamicTheme} />}
                    />

                    <Route exact path='/dashboard/' element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/'
                        element={<Dashboard theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/dashboard/vpn/accounts/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/vpn/accounts/'
                        element={<Accounts theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/dashboard/vpn/accounts/:accountuuid'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/vpn/accounts/:accountuuid'
                        element={<Account theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/dashboard/vpn/accounts/add/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/vpn/accounts/add/'
                        element={<AddAccount theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route exact path='/admin/' element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/'
                        element={<Admin theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route exact path='/admin/vpn/' element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/vpn/'
                        element={<VPN theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/admin/vpn/server/:serveruuid/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/vpn/server/:serveruuid/'
                        element={<Server theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/admin/vpn/servers/add/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/vpn/servers/add/'
                        element={<AddServer theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/admin/vpn/tunnels/add/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/vpn/tunnels/add/'
                        element={<AddTunnel theme={dynamicTheme} />}
                      />
                    </Route>

                    <Route
                      exact
                      path='/admin/vpn/server/change/:serveruuid'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/admin/vpn/server/change/:serveruuid'
                        element={<ChangeServer theme={dynamicTheme} />}
                      />
                    </Route>
                  </Routes>
                  <CustomBottomNavigation />
                </BrowserRouter>
              </Box>
            </ThemeProvider>
          </StylesProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default connect(MapStateToProps)(App);
