import React from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
// import SnackbarContent from "@mui/material/SnackbarContent";
import Alert from "@mui/material/Alert";
import { connect, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import * as snackbarActions from "../../store/actions/snackbarActions";

const MapStateToProps = (state) => {
  return {
    snackbar: state.snackbarReducer,
  };
};

function SnackBarMessage(props) {
  const dispatch = useDispatch();

  function snackBarTransitionUp(props) {
    return <Slide {...props} direction='down' />;
  }

  const handleClose = (event, reason) => {
    dispatch(snackbarActions.newSnackbarResetAction());
  };

  return (
    <>
      {props.snackbar.message.show === true ? (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={props.snackbar.message.show}
          {...(props.snackbar.message.autoHide === true
            ? { autoHideDuration: 6000 }
            : "")}
          onClose={handleClose}
          TransitionComponent={snackBarTransitionUp}>
          {/* <SnackbarContent
            style={{
              backgroundColor: "#b71c1c",
            }}
            message={<span id='client-snackbar'>aaaa</span>}
          /> */}

          <Alert
            severity={props.snackbar.message.severity}
            variant='filled'
            {...(props.snackbar.message.autoHide === true
              ? ""
              : {
                  action: (
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      sx={{ p: 0.5 }}
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  ),
                })}
            sx={{
              "& .MuiAlert-icon": {
                display: "none",
              },
            }}>
            {props.snackbar.message.content}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </>
  );
}
export default connect(MapStateToProps)(SnackBarMessage);
