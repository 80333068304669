import {
  createStore,
  combineReducers,
  // compose,
  // applyMiddleware
} from "redux";
// import thunk from "redux-thunk";
import systemReducer from "./reducers/systemReducer";
import snackbarReducer from "./reducers/snackbarReducer";

const allReducers = combineReducers({
  systemReducer: systemReducer,
  snackbarReducer: snackbarReducer,
});

const initialState = {
  systemReducer: {
    activeRoom: "",
    userName: "",
    authToken: "",
    themeColor: "#000000",
    themeMode: "light",
    themeDir: "ltr",
    themeLng: "en",
  },
  snackbarReducer: { message: { content: "", type: 0, show: false } },
};

// Creation of the main store
const store = createStore(
  allReducers,
  initialState
  // compose(applyMiddleware(...middleware))
);
export default store;
