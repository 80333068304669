import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";

import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";
import { changeTunnelName } from "../../../internal/changeTunnelName";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function Accounts(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [auth, setAuth] = React.useState(null);
  const [accountsList, setAccountsList] = React.useState([]);
  const [fetchingList, setFetchingList] = React.useState(true);
  const [userCredit, setUserCredit] = React.useState("");
  const [userTunnelCredit, setUserTunnelCredit] = React.useState("");
  const [fetchingUserCredit, setFetchingUserCredit] = React.useState(false);

  function goToAddVPNAccount() {
    navigate("/dashboard/vpn/accounts/add");
  }

  useEffect(() => {
    setAuth(getAuthToken());

    function goToAccount(accountUUID) {
      if (accountUUID) {
        navigate("/dashboard/vpn/accounts/" + accountUUID + "/");
      }
    }

    function fetchUserCredit() {
      if (getAuthToken() && getAuthToken().token) {
        const rawData = {
          client: "web",
          clientVersion: "1.0",
        };
        setFetchingUserCredit(true);
        axios({
          method: "post",
          headers: {
            Authorization: "Bearer " + getAuthToken().token,
            "Content-Type": "application/json",
          },
          data: rawData,
          url: config.url.APP_SERVER + "/api/v1/auth/getusercreditbytoken",
        })
          .then(function (res) {
            if (res.data && res.data.response) {
              if (
                res.data.response.status === 1 &&
                res.data.response.httpCode === 200
              ) {
                if (res.data.response.detailedResponse[0].userCredit >= 0) {
                  setUserCredit(
                    res.data.response.detailedResponse[0].userCredit
                  );
                }

                if (
                  res.data.response.detailedResponse[0].userTunnelCredit >= 0
                ) {
                  setUserTunnelCredit(
                    res.data.response.detailedResponse[0].userTunnelCredit
                  );
                }
                setFetchingUserCredit(false);
              } else {
                setFetchingUserCredit(false);
              }
            }
          })
          .catch(function (res) {
            setFetchingUserCredit(false);
          });
      }
    }

    function getAccounts() {
      const rawData = { client: "web", clientVersion: "1.0" };
      setFetchingList(true);
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.APP_SERVER + "/api/v1/accounts/getuseraccounts",
      })
        .then(function (res) {
          setFetchingList(false);
          if (res.data && res.data.response) {
            if (res.data.response.status === 1) {
              let temp = [];
              res.data.response.detailedResponse[0].accounts.map(function (
                item,
                key
              ) {
                if (item) {
                  temp.push(
                    <Box key={key}>
                      <Stack direction='row'>
                        <Box
                          sx={{
                            borderLeft: `5px solid ${
                              item.accountEnabled ? "green" : "red"
                            }`,
                          }}
                        />
                        <ListItemButton
                          onClick={() => goToAccount(item.accountUUID)}
                          sx={{ textAlign: "center !important" }}>
                          <ListItemText
                            sx={{
                              color: item.accountEnabled ? "black" : "red",
                            }}>
                            <Typography variant='body2'>
                              {item.accountName}({item.accountVPNType}
                              {item.accountTunnelEnabled ? (
                                changeTunnelName(item.accountTunnelType)
                              ) : (
                                <></>
                              )}
                              )
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </Stack>
                    </Box>
                  );
                }

                return item;
              });
              setAccountsList(temp);
            } else if (
              res.data.response.status === 0 &&
              res.data.response.error != null &&
              res.data.response.error.errorType === "RESOURCE_NOT_FOUND"
            ) {
              //pass
            } else if (
              res.data.response.status === 0 &&
              res.data.response.error != null
            ) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.error.errorContent[0].message,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setFetchingList(false);
          if (
            res.response.data &&
            res.response.data.response.status === 0 &&
            res.response.data.response.error != null &&
            res.response.data.response.error.errorContent[0].message ===
              "No accounts found"
          ) {
            console.log("Client: Error fetching accounts");
          } else {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: "Client: Error fetching accounts",
                severity: "error",
                autoHide: true,
              })
            );
          }
        });
    }

    if (getAuthToken() && getAuthToken().token) {
      getAccounts();
      fetchUserCredit();
    }
  }, [dispatch, navigate]);

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "100px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        {auth && auth.token ? (
          <Stack spacing={2}>
            <Box>
              {auth && auth.token ? (
                <Box sx={{ textAlign: "center" }}>
                  {fetchingUserCredit ? (
                    <CircularProgress />
                  ) : userCredit >= 0 && userTunnelCredit >= 0 ? (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.50)",
                        borderRadius: "4px",
                      }}>
                      <Stack direction='row'>
                        <Box sx={{ textAlign: "start", width: "100%" }}>
                          <b>{t("DASHBOARD VPN ACCOUNTS USER CREDIT")}:</b>{" "}
                          {userCredit}
                        </Box>
                        <Box></Box>
                        <Box sx={{ textAlign: "end", width: "100%" }}>
                          <b>
                            {t("DASHBOARD VPN ACCOUNTS USER TUNNEL CREDIT")}:
                          </b>{" "}
                          {userTunnelCredit}
                        </Box>
                      </Stack>
                    </Box>
                  ) : (
                    <Box>Error when fetching user credit</Box>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Button
              variant='outlined'
              fullwidth='true'
              onClick={() => goToAddVPNAccount()}
              sx={{
                minWidth: "20%",
              }}
              startIcon={<AddIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {t("DASHBOARD VPN CREATE NEW ACCOUNT")}
              </Box>
            </Button>
            <Box sx={{ textAlign: "center !important" }}>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("DASHBOARD VPN ACCOUNTS")}
              </Typography>
            </Box>
            <Box justifyContent='left' alignItems='left'>
              {fetchingList === true ? (
                <Box>
                  <Skeleton />
                  <Skeleton animation={false} />
                  <Skeleton animation='wave' />
                </Box>
              ) : accountsList.length > 0 ? (
                accountsList
              ) : (
                <Box>{t("DASHBOARD VPN ACCOUNTS NO ACCOUNTS")}</Box>
              )}
            </Box>
          </Stack>
        ) : (
          <Box>{t("SETTINGS LOGIN")}</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Accounts);
